@import '@/stylesheets/vendor/onair-icons/style.scss';
@import 'variables';

.bmpui-hidden,
.bmpui-disabled,
.bmpui-disabled > * {
  cursor: default;
  pointer-events: none;
}

.bmpui-label {
  display: none;
}

.bmpui-ui-spacer {
  height: 100%;
  width: 100%;
}

// Main container
.bmpui-ui-uicontainer {
  position: absolute;
  inset: 0;
  overflow: hidden;
  container-type: size;

  &.bmpui-player-state-playing.bmpui-controls-hidden * {
    cursor: none;
  }
}

// Play/pause overlay
.bmpui-ui-hugeplaybacktogglebutton {
  position: absolute;
  inset: 0;
  cursor: default;

  .bmpui-image {
    height: 100%;
    width: 100%;
  }
}

// Buffering overlay
.bmpui-ui-buffering-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: opacity 0.6s visibility 0.6s;
  transition: opacity 0.6s;
  opacity: 1;
  visibility: visible;
  background-color: rgba(18, 17, 18, 0.7);

  &.bmpui-hidden {
    opacity: 0;
    visibility: hidden;
  }

  .bmpui-loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    background:
      radial-gradient(farthest-side, white 94%, #0000) top/8px 8px no-repeat,
      conic-gradient(#0000 30%, white);
    mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
    -webkit-mask: radial-gradient(
      farthest-side,
      #0000 calc(100% - 8px),
      #000 0
    );
    animation: spin 1.2s infinite linear;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

// Control bar
.bmpui-ui-controlbar {
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(18, 17, 18, 0) 0%,
    rgba(18, 17, 18, 0.78) 70.83%,
    var(--grey-900) 100%
  );
  padding: 0.5rem 1rem;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding: 1rem 6.75rem;
  }

  &.bmpui-hidden {
    opacity: 0;
  }

  .bmpui-controlbar-top > .bmpui-container-wrapper,
  .bmpui-controlbar-bottom > .bmpui-container-wrapper {
    display: flex;
  }
}

// Seek bar & volume bar
.bmpui-ui-volumeslider,
.bmpui-ui-seekbar {
  cursor: pointer;
  height: 1rem;
  position: relative;
  width: 100%;
  border-radius: 10px;
}

.bmpui-ui-seekbar:focus,
.bmpui-ui-seekbar:hover {
  .bmpui-seekbar {
    opacity: 1;
  }
}

.bmpui-seekbar {
  opacity: 0.7;

  .bmpui-seekbar-markers,
  .bmpui-seekbar-markers > .bmpui-seekbar-marker,
  .bmpui-seekbar-playbackposition-marker,
  .bmpui-seekbar-playbackposition,
  .bmpui-seekbar-seekposition,
  .bmpui-seekbar-bufferlevel,
  .bmpui-seekbar-backdrop {
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    height: 0.25rem;
    position: absolute;
    inset: 0 auto 0 0;
    margin: auto;
    width: 100%;
  }

  .bmpui-seekbar-backdrop {
    background-color: var(--grey-600);
    opacity: 0.7;
  }

  .bmpui-seekbar-bufferlevel {
    background-color: var(--grey-50);
    -webkit-transition: transform 0.3s linear;
    transition: transform 0.3s linear;
  }

  .bmpui-seekbar-playbackposition {
    background-color: var(--primary-500);
    -webkit-transition: transform 0.1s linear;
    transition: transform 0.1s linear;
  }

  .bmpui-seekbar-seekposition {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .bmpui-seekbar-playbackposition-marker {
    height: 0.75rem;
    width: 0.75rem;
    left: -0.375rem;
    background-color: var(--primary-500);
    border-radius: 50%;
    filter: drop-shadow(0px 5px 10px rgba(25, 24, 26, 0.5));
  }
}

.bmpui-ui-volumeslider {
  margin: auto 0.5rem;
  width: 4rem;
  flex-shrink: 0;

  @media (max-width: map-get($grid-breakpoints, sm)) {
    display: none;
  }

  .bmpui-seekbar {
    opacity: 1;

    .bmpui-seekbar-playbackposition {
      background-color: var(--grey-50);
    }

    .bmpui-seekbar-playbackposition-marker {
      background-color: var(--grey-50);
    }

    .bmpui-seekbar-bufferlevel {
      background-color: initial;
    }
  }
}

// Buttons
.bmpui-ui-piptogglebutton,
.bmpui-ui-airplaytogglebutton,
.bmpui-ui-casttogglebutton,
.bmpui-ui-settingstogglebutton,
.bmpui-ui-volumetogglebutton,
.bmpui-ui-fullscreentogglebutton,
.bmpui-ui-playbacktogglebutton,
.bmpui-ui-button {
  font-family: $icomoon-font-family;
  border-radius: 9999px;
  line-height: 0;
  cursor: pointer;
  font-size: 1.5rem;
  height: 2rem;
  width: fit-content;
  padding: 0.25rem 0.5rem;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    font-size: 1.25rem;
    height: 1.75rem;
  }

  &:hover {
    -webkit-filter: drop-shadow(0 0 1px currentColor);
    filter: drop-shadow(0 0 1px currentColor);
  }

  &.bmpui-hidden {
    display: none;
  }
}

// Play/pause button
.bmpui-ui-playbacktogglebutton {
  &::before {
    content: $oa-play;
  }

  &.bmpui-on {
    &::before {
      content: $oa-pause;
    }
  }
}

// Volume button
.bmpui-ui-volumetogglebutton {
  // Needed to make sure the speaker does not move when the volume level changes
  text-align: left;
  min-width: 3rem;

  &.bmpui-muted {
    &::before {
      content: $oa-loudspeaker-off;
    }
  }

  &.bmpui-unmuted {
    &[data-bmpui-volume-level-tens='0'],
    &[data-bmpui-volume-level-tens='1'],
    &[data-bmpui-volume-level-tens='2'],
    &[data-bmpui-volume-level-tens='3'] {
      &::before {
        content: $oa-loudspeaker;
      }
    }

    &[data-bmpui-volume-level-tens='4'],
    &[data-bmpui-volume-level-tens='5'],
    &[data-bmpui-volume-level-tens='6'] {
      &::before {
        content: $oa-loudspeaker-low;
      }
    }

    &[data-bmpui-volume-level-tens='7'],
    &[data-bmpui-volume-level-tens='8'],
    &[data-bmpui-volume-level-tens='9'],
    &[data-bmpui-volume-level-tens='10'] {
      &::before {
        content: $oa-loudspeaker-loud;
      }
    }
  }
}

// Settings button
.bmpui-ui-settingstogglebutton {
  &::before {
    content: $oa-settings;
  }

  &.bmpui-on {
    color: var(--primary-500);
  }
}

// Pip button
.bmpui-ui-piptogglebutton {
  &::before {
    content: $oa-pip;
  }

  &.bmpui-on {
    &::before {
      content: $oa-pip-off;
    }
  }
}

// Full screen button
.bmpui-ui-fullscreentogglebutton {
  &::before {
    content: $oa-full-screen;
  }

  &.bmpui-on {
    &::before {
      content: $oa-exit-fullscreen;
    }
  }
}

// Airplay button
.bmpui-ui-airplaytogglebutton {
  &::before {
    content: $oa-airplay;
  }
}

// Chromecast button
.bmpui-ui-casttogglebutton {
  &::before {
    content: $oa-chromecast;
  }

  &.bmpui-on {
    &::before {
      content: $oa-chromecast-casting;
    }
  }
}

// Time labels
.bmpui-time-labels {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  line-height: 0;
  font-size: 0.8125rem;
  color: var(--grey-200);

  @media (max-width: map-get($grid-breakpoints, sm)) {
    margin-left: 0;
    font-size: 0.6875rem;
  }

  .bmpui-ui-playbacktimelabel {
    white-space: nowrap;

    &.bmpui-hidden {
      display: none;
    }

    &:first-child:not(.bmpui-hidden)::after {
      content: ' / ';
    }

    &.bmpui-ui-playbacktimelabel-live {
      text-transform: uppercase;
      cursor: pointer;

      &::before {
        color: var(--grey-600);
        content: '●';
        padding-right: 0.5rem;
      }

      &.bmpui-ui-playbacktimelabel-live-edge::before {
        color: var(--primary-500);
      }
    }
  }
}

// Subtitles
.bmpui-ui-subtitle-overlay {
  bottom: 1rem;
  font-size: 1.5rem;
  left: 0;
  right: 0;
  top: 0;
  pointer-events: none;
  position: absolute;
  text-align: center;
  -webkit-transition: bottom 0.15s ease-out;
  transition: bottom 0.15s ease-out;

  &.bmpui-controlbar-visible {
    bottom: 5rem;
  }

  &.bmpui-hidden {
    display: none;
  }

  .bmpui-ui-subtitle-label {
    text-shadow:
      -1px -1px 0 #000,
      0 -1px 0 #000,
      1px -1px 0 #000,
      -1px 0 0 #000,
      1px 0 0 #000,
      -1px 1px 0 #000,
      0 1px 0 #000,
      1px 1px 0 #000;
    color: #fff;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

// Seekbar label
.bmpui-ui-seekbar-label {
  opacity: 1;
  -webkit-transition:
    opacity 0.3s,
    visibility 0.3s;
  transition:
    opacity 0.3s,
    visibility 0.3s;
  visibility: visible;
  bottom: 100%;
  left: 0;
  margin-bottom: 0.5rem;
  pointer-events: none;
  position: absolute;
  text-align: center;

  &.bmpui-hidden {
    opacity: 0;
    visibility: hidden;
  }

  > .bmpui-container-wrapper {
    margin-left: -50%;
    margin-right: 50%;
    position: relative;
  }

  .bmpui-seekbar-label-inner {
    border-bottom: 0.125rem solid #fff;

    &::after {
      content: ' ';
      border: solid transparent;
      border-top-color: #fff;
      border-width: 0.25rem;
      margin-left: -0.25rem;
      pointer-events: none;
      position: absolute;
      left: 50%;
      top: 100%;
    }

    > .bmpui-container-wrapper {
      position: relative;

      .bmpui-seekbar-thumbnail {
        width: 5rem;
      }

      .bmpui-seekbar-label-metadata {
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.7)
        );
        padding: 0.5rem;
        position: absolute;
        bottom: 0;
        width: 100%;

        .bmpui-seekbar-label-time {
          display: block;
          line-height: 0.8rem;
        }

        .bmpui-seekbar-label-title {
          display: block;
          margin-bottom: 0.3rem;
          white-space: normal;
        }
      }
    }
  }
}

// Settings panel
.bmpui-ui-settings-panel {
  z-index: 2;
  opacity: 1;
  visibility: visible;
  -webkit-transition:
    opacity 0.3s,
    visibility 0.3s,
    height 0.35s cubic-bezier(0.4, 0, 0.2, 1),
    width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition:
    opacity 0.3s,
    visibility 0.3s,
    height 0.35s cubic-bezier(0.4, 0, 0.2, 1),
    width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: var(--grey-900);
  position: absolute;
  bottom: 1rem;
  right: 7.5rem;
  max-height: 150px;
  overflow-y: auto;

  @container (min-height: 300px) {
    max-height: 250px;
  }

  @container (min-height: 500px) {
    max-height: 450px;
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    right: 14.5rem;
  }

  .bmpui-container-wrapper {
    height: 100%;
  }

  &.bmpui-hidden {
    opacity: 0;
    visibility: hidden;
  }

  .bmpui-ui-settings-panel-page {
    display: none;
    padding: 0.5rem 1rem;

    &.bmpui-active {
      display: block;
    }

    .bmpui-ui-label {
      display: none;
    }

    .bmpui-ui-settingspanelpageopenbutton {
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 2.5rem;
      padding: 0.25rem 0;
      font-size: 1.125rem;
      width: 100%;

      .bmpui-label {
        min-width: 8rem;
        display: flex;

        &::before {
          font-family: $icomoon-font-family;
          margin-right: 1rem;
        }
      }

      &.bmpui-subtitles {
        .bmpui-label::before {
          content: $oa-caption;
        }
      }

      &.bmpui-audio {
        .bmpui-label::before {
          content: $oa-audio;
        }
      }

      &::after {
        font-family: $icomoon-font-family;
        font-size: 0.75rem;
        content: $oa-chevron-right;
        margin-left: 1.5rem;
      }
    }

    .bmpui-settings-list {
      .bmpui-ui-label {
        display: block;
        font-size: 1.125rem;
        font-weight: 700;
        margin: 1rem 0;
      }
    }

    .bmpui-ui-settingspanelpagebackbutton {
      text-align: left;
      position: relative;
      width: 8em;
    }
    .bmpui-ui-settingspanelpagebackbutton .bmpui-label {
      color: var(--primary-500);
      display: block;

      &::before {
        font-family: $icomoon-font-family;
        font-size: 0.75rem;
        content: $oa-chevron-left;
        margin-right: 0.5rem;
      }
    }
  }
}

// Listbox
.bmpui-ui-listbox .bmpui-ui-listbox-button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  font-size: 1.125rem;
  height: 100%;
  min-width: 10rem;
  padding: 0.5rem;
  width: 100%;

  .bmpui-label {
    display: inherit;
    text-align: left;
    text-transform: capitalize;
  }

  &:hover {
    box-shadow: -0.125rem 0 0 var(--grey-50);
  }

  &.bmpui-selected {
    box-shadow: -0.125rem 0 0 var(--primary-500);
    font-weight: 700;
  }
}

// Cast overlay
.bmpui-ui-cast-status-overlay {
  position: absolute;
  inset: 0;
  opacity: 1;
  -webkit-transition:
    opacity 0.3s,
    visibility 0.3s;
  transition:
    opacity 0.3s,
    visibility 0.3s;
  visibility: visible;
  background: #111
    url("data:image/svg+xml;charset=utf-8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 32 32' style='enable-background:new 0 0 32 32%3B' xml:space='preserve'%3E%3Cstyle type='text/css'%3E.st0%7Bfill:%23FFFFFF%3B%7D%3C/style%3E%3Cg id='Rectangle_9_copy'%3E%3Cg%3E%3Cpath class='st0' d='M27.7%2C5.4H6.4c-1.2%2C0-2.1%2C1-2.1%2C2.1v2.1h2.1V7.5h21.3v12.8h-8.5v2.1h8.5c1.2%2C0%2C2.1-1%2C2.1-2.1V7.5 C29.8%2C6.3%2C28.9%2C5.4%2C27.7%2C5.4z'/%3E%3C/g%3E%3C/g%3E%3Cg id='Rectangle_12'%3E%3Cg%3E%3Cpath class='st0' d='M2.2%2C12.8v2.1c6.5%2C0%2C11.7%2C5.2%2C11.7%2C11.7H16C16%2C19%2C9.8%2C12.8%2C2.2%2C12.8z M2.2%2C18.1v2.1c3.5%2C0%2C6.4%2C2.9%2C6.4%2C6.4 h2.1C10.7%2C22%2C6.9%2C18.1%2C2.2%2C18.1z M2.2%2C23.4v3.2h3.2C5.4%2C24.8%2C4%2C23.4%2C2.2%2C23.4z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
    center no-repeat;
  background-size: 7em 7em;

  &.bmpui-hidden {
    opacity: 0;
    visibility: hidden;
  }

  .bmpui-ui-cast-status-label {
    font-size: 1.2rem;
    margin: 0 2rem;
    pointer-events: none;
    position: absolute;
    inset: 60% 0 0;
    text-align: center;

    * {
      pointer-events: none;
    }
  }
}

// Error overlay
.bmpui-ui-errormessage-overlay {
  position: absolute;
  inset: 0;
  background-color: #111;
  pointer-events: none;

  &.bmpui-hidden {
    display: none;
  }

  .bmpui-ui-errormessage-label {
    color: #fff;
    font-size: 1.2em;
    position: absolute;
    left: 3rem;
    right: 3rem;
    -ms-transform: translateY(-50%);
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    white-space: pre-line;

    ul {
      color: #999;
      font-size: 0.9rem;
      padding: 0;

      li {
        list-style: none;
      }
    }
  }

  .bmpui-ui-tvnoisecanvas {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    -webkit-filter: blur(4px);
    filter: blur(4px);
  }
}
